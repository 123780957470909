import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "./link";

const BoxHelp = () => {
  const { space } = useStaticQuery(graphql`
    {
      space: file(relativePath: { eq: "bg-space.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <>
      <section className="section">
        <div className="box-help">
          <GatsbyImage
            image={getImage(space)}
            alt="img background help"
            objectPosition="center center"
            className="box-help__bg-img"
          />
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-lg-8 d-flex align-items-end">
                <div className="box-help__text text-uppercase">
                  <span className="d-block">Come</span>{" "}
                  <span className="d-block small">possiamo</span>{" "}
                  <span className="d-block">aiutarti?</span>
                </div>
              </div>
              <div className="col-12 col-lg-4 d-lg-flex justify-content-lg-end">
                <div className="d-flex flex-wrap justify-content-around d-lg-block">
                  <div>
                    <Link to="/faq/" className="btn-link">
                      <span className="btn-link__circle"></span>
                      <span className="btn-link__text">
                        Domande <span className="d-block bold">& faq</span>
                      </span>
                    </Link>
                  </div>
                  <div>
                    <Link to="/contatti/" className="btn-link">
                      <span className="btn-link__circle"></span>
                      <span className="btn-link__text">
                        Richiedi <span className="d-block bold">contatto</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoxHelp;
