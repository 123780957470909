import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "./link";

const EngageBanner = () => {
  const { tunnelSmall } = useStaticQuery(graphql`
    {
      tunnelSmall: file(relativePath: { eq: "bg-tunnel-small.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <>
      <section className="section">
        <div className="box-engage-banner">
          <GatsbyImage
            image={getImage(tunnelSmall)}
            alt="img background megamenu"
            objectPosition="center bottom"
            className="box-engage-banner__bg-img"
          />
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div>
                  <div className="section__name">Programma +</div>
                  <div className="box-engage-banner__title text-uppercase">Engage</div>
                  <div className="box-engage-banner__subject text-uppercase">
                    Workshop, keynote, case study, networking.
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex justify-content-center justify-content-md-end align-items-md-center">
                  <Link to="/programma/" className="btn-link">
                    <span className="btn-link__circle"></span>
                    <span className="btn-link__text">
                      Scopri il <span className="d-block bold">programma</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EngageBanner;
