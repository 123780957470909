import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "../components/link";

const BannerTickets = () => {
  const { world } = useStaticQuery(graphql`
    {
      world: file(relativePath: { eq: "bg-world.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <>
      <section className="section">
        <div className="banner-tickets">
          <GatsbyImage
            image={getImage(world)}
            alt="img background world"
            objectPosition="center center"
            className="banner-tickets__bg-img"
          />
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="banner-tickets__text d-flex align-items-center h-100">
                  <div className="text-center text-sm-start">
                    <p>
                      Partecipa al più grande evento <strong>italiano</strong> dedicato alle{" "}
                      <strong>Intranet</strong> e alla <strong>comunicazione interna</strong>
                    </p>
                    <div className="banner-tickets__date text-uppercase">
                      29 maggio 2025, Milano
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 text-center text-sm-end">
                <Link to="/ticket/" className="btn-link btn-link--small">
                  <span className="btn-link__circle"></span>
                  <span className="btn-link__text">
                    Acquista <span className="d-block bold">ticket</span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerTickets;
