import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BoxIniziativa = () => {
  const { intranetManagement, ariadneGroup } = useStaticQuery(graphql`
    {
      intranetManagement: file(relativePath: { eq: "intranet-management-logo-white.png" }) {
        childImageSharp {
          gatsbyImageData(height: 60, placeholder: BLURRED)
        }
      }
      ariadneGroup: file(relativePath: { eq: "ariadne-group-logo-white.png" }) {
        childImageSharp {
          gatsbyImageData(height: 60, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <>
      <section className="section">
        <div className="box-iniziativa">
          <div className="section__bg-title text-center" aria-hidden="true">
            Iniziativa
          </div>
          <div className="container-md">
            <div className="row">
              <div className="col-12 col-lg-4 d-flex align-items-center">
                <div className="text-uppercase">
                  Iniziativa <span className="d-inline-block d-md-block">promossa da</span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center justify-content-lg-end align-items-center">
                <a
                  href="https://www.intranetmanagement.it/"
                  title="Intranet Management"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="box-iniziativa__link"
                >
                  <GatsbyImage
                    image={getImage(intranetManagement)}
                    alt="logo Intranet Management"
                    objectPosition="center center"
                    className="box-iniziativa__img"
                  />
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center justify-content-lg-end">
                <a
                  href="https://www.ariadnegroup.it/"
                  title="Ariadne Group"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="box-iniziativa__link"
                >
                  <GatsbyImage
                    image={getImage(ariadneGroup)}
                    alt="logo Ariadne Group"
                    objectPosition="center center"
                    className="box-iniziativa__img"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BoxIniziativa;
